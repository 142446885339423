import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Features/SD_Card/SuccessBox';
import SDTable from 'components/Web_User_Interface/1080p_Series/Features/SD_Card/sdTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // SD Card",
  "path": "/Web_User_Interface/1080p_Series/Features/SD_Card/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Please check the corresponding box and click submit to either format or unmount your SD card. Your SD card might be unavailable after an unexpected restart. Please wait for the operating system to check the file system for errors.",
  "image": "./WebUI_1080p_SearchThumb_Features_SD_Card.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Features_SD_Card.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Features Menu // SD Card' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Please check the corresponding box and click submit to either format or unmount your SD card. Your SD card might be unavailable after an unexpected restart. Please wait for the operating system to check the file system for errors.' image='/images/Search/WebUI_1080p_SearchThumb_Features_SD_Card.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Features_SD_Card.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Features/SD_Karte/' locationFR='/fr/Web_User_Interface/1080p_Series/Features/SD_Card/' crumbLabel="SD Card" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a63202396088a21b1ac717f4f3d73a69/bb27a/1080p_Settings_Features_SD_Card.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.1304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACuklEQVQoz12SW08TQRSARxAe5BoMgmRLXSulSFoUA1FDAUM0FTFUoAVlCy1QKdIHbnvrXma2ghE18UUDItEEf4ZiYiSRB8U3NUTUFyPRIhgNIu3umt0WL3w5mcycM1/OZGYArcOyLMvQgxRHTs0NTT8PTswHJxPRP/GMvL/ATD6kKZZjQkMDgzXVtUXmYsLTAUYomqJpURR9Xm+ZzfbuY+TruvLle3R1XY3Hyg959af6enFpLOgXunoCTnfnqfrT1jKPyw1GSE2GEBIejxG3RCIRVVUVOaZuociyqqqfP7y9N3zx9iAfau6DZ+vbj5R73RfAMEmTlCZ3eDyNDvu3tTVZVmRFUVQ1EdpUXXqzOOD0jXf13Ql2DxGhclvt+TYCkDRDJWSitqJkeXl5Y2Njc/OXHIsqckyORaMx7RQLL15e72x4dc09z1XO30I32s8MeloASdNxmSA8mAGPRFbUrW6qPsY+LUdnnyzNPRWCzqajVv9x04OOPfN+w82gW7swUr8wr9eXmZXV6m71+nzdHcTc9ND7R+OPp0JMfyAc7J+ZvltZXWPaV2LE8NycvGZLZrizAVA0QzOMKApd3d07U1KBTu6uHd6qnGFHvug4VGbIc560Uwxdgu8/aLFgGJaRkW7KTrnkcgBGR3sqny85OQkAYEwHJw6kpKYmZaSlz7oMM/Y0h/1YlbsFLzbhZjx7dyZITjalgZ5zdUD7HQzD83wg0FuAGcyFe9lGowEryMcM/hLLVRx3FZlLK8ocfmdLb1sd4SitsFkPWwuMhU3NTZr8x0cIhRESBShChBC8AmEIIRpCURAFng+HpdGxUQSRJEkIajv+yhzPSwhpuS1EhMISuoy03ZIkQZ1/6giEWDak65ze+b+itt4uwHgq3pmkmRGKoWiG4zheEHhB5AUxzjYtcRwdjuMghL8BHomVhOTP3BAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a63202396088a21b1ac717f4f3d73a69/e4706/1080p_Settings_Features_SD_Card.avif 230w", "/en/static/a63202396088a21b1ac717f4f3d73a69/d1af7/1080p_Settings_Features_SD_Card.avif 460w", "/en/static/a63202396088a21b1ac717f4f3d73a69/7f308/1080p_Settings_Features_SD_Card.avif 920w", "/en/static/a63202396088a21b1ac717f4f3d73a69/b0250/1080p_Settings_Features_SD_Card.avif 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a63202396088a21b1ac717f4f3d73a69/a0b58/1080p_Settings_Features_SD_Card.webp 230w", "/en/static/a63202396088a21b1ac717f4f3d73a69/bc10c/1080p_Settings_Features_SD_Card.webp 460w", "/en/static/a63202396088a21b1ac717f4f3d73a69/966d8/1080p_Settings_Features_SD_Card.webp 920w", "/en/static/a63202396088a21b1ac717f4f3d73a69/f16bf/1080p_Settings_Features_SD_Card.webp 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a63202396088a21b1ac717f4f3d73a69/81c8e/1080p_Settings_Features_SD_Card.png 230w", "/en/static/a63202396088a21b1ac717f4f3d73a69/08a84/1080p_Settings_Features_SD_Card.png 460w", "/en/static/a63202396088a21b1ac717f4f3d73a69/c0255/1080p_Settings_Features_SD_Card.png 920w", "/en/static/a63202396088a21b1ac717f4f3d73a69/bb27a/1080p_Settings_Features_SD_Card.png 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a63202396088a21b1ac717f4f3d73a69/c0255/1080p_Settings_Features_SD_Card.png",
              "alt": "Web User Interface - 1080p Series - Features SD Card",
              "title": "Web User Interface - 1080p Series - Features SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "the-sd-card-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#the-sd-card-menu",
        "aria-label": "the sd card menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The SD Card Menu`}</h3>
    <p>{`A click on the folder button will lead you to the SD card directory where all your snapshots and videos will be stored. Use your camera admin login to access the directory. But there are also more convenient ways to access your recordings via `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/SD_Card_Access/"
      }}>{`Third-Party Software`}</a>{` or our mobile phone app InstarVision for `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPhone/Recording/"
      }}>{`iPhone`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/iPad/Recording/"
      }}>{`iPad`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/Recording/"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/Recording/"
      }}>{`Windows Phone`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/Recording/"
      }}>{`Windows Metro`}</a>{`.`}</p>
    <p>{`FAT32`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SDTable mdxType="SDTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    {/* |   |   |
     |---|---|
     | __Open SD folder__ | A click on the folder button will lead you to the SD card directory where all your snapshots and videos will be stored. Use your camera admin login to access the directory. But there are also more convenient ways to access your recordings via [Third-Party Software](/Motion_Detection/SD_Card_Access/) or our mobile phone app InstarVision for [iPhone](/Software/iOS/InstarVision/iPhone/Recording/), [iPad](/Software/iOS/InstarVision/iPad/Recording/), [Android](/Software/Android/InstarVision/Recording/), [Windows Phone](/Software/Windows/InstarVision/Windows_Phone/Recording/) and [Windows Metro](/Software/Windows/InstarVision/Metro/Recording/). |
     | __Eject SD card__ | Unmount your SD card __before__ you unplug it from your camera. Your camera´s operating system will try to re-mount the SD card in regular intervals. |
     | __Format SD card__ | Your camera will delete the oldest folder __automatically__ once the card capacity is reached. So there is always enough space for new recordings! Use the format command if you want to remove all recordings, or if your SD card´s file system is damaged. The latter might happen, if you restart/unplug your camera while your camera is recording a video. | */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      